<script setup lang="ts">
</script>
<template>
  <div
    class="top"
    id="top"
  >
    <div class="top-content">
      <div class="top-content-left">
        <img
          class="top-content-left-image"
          src="@/assets/images/Artboard-17.webp"
          alt="">
      </div>
      <div class="top-content-text">
        <h1 class="top-content-text-title">CZ-TECH.RU</h1>
        <p class="top-content-text-description">
          Привлекайте клиентов с помощью cz-tech.ru <br>
          — платформа для самостоятельного <br>
          размещения таргетированной рекламы в <br>
          разных интернет источниках
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: get-vw(1150px);
  margin-left: auto;
  margin-right: auto;
}
.top {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(70px);
    &-left {
      width: 50%;
      &-image {
        margin-left: get-vw(-55px);
        height: 110%;
        width: 110%;
      }
    }
    &-text {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: get-vw(50px);
      &-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: get-vw(50px);
        line-height: get-vw(65px);
        color: #4244E6;
        text-shadow: 0 get-vw(4px) get-vw(4px) rgba(0, 0, 0, 0.25);
        padding-left: get-vw(15px);
      }
      &-description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(18px);
        line-height: get-vw(28px);
        color: #000000;
        padding-left: get-vw(20px);
        margin-top: get-vw(20px);
        border-left: get-vw(5px) solid #4C4EF9;
      }
    }
  }
}
</style>
